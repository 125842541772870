import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { User } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';

@Injectable({ providedIn: 'root' })
export class OwnershipService {
  constructor(
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
  ) {}

  isMyCompany(): boolean {
    return this.isLoggedInUserOwnerOfCompany(
      this.companyStateService?.activeCompany,
      this.userStateService?.loggedInUser,
    );
  }

  isMyCompany$: Observable<boolean> = combineLatest([
    this.companyStateService.activeCompany$,
    this.userStateService.loggedInUser$,
  ]).pipe(map(([company, user]) => this.isLoggedInUserOwnerOfCompany(company, user)));

  private isLoggedInUserOwnerOfCompany(
    company: Company | undefined | null,
    loggedInUser: User | undefined | null,
  ): boolean {
    return company?.ownerId === loggedInUser?.id;
  }
}
